import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TagList from "../components/tagList"
import { rhythm, scale } from "../utils/typography"

const BlogListTemplate = ({ data, pageContext, location }) => {
  const { currentPage, numPages } = pageContext
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : "/" + (currentPage - 1).toString()
  const nextPage = "/" + (currentPage + 1).toString()

  return (
    <Layout location={location} title={siteTitle} style="home">
      <SEO title={siteTitle} />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug}>
            <header
              style={{
                marginBottom: rhythm(1 / 4),
              }}>
              <h2
                style={{
                  marginBottom: 0,
                }}
              >
                <Link to={node.fields.slug}>
                  {title}
                </Link>
              </h2>
              {node.frontmatter.date && (
                <small>
                  {node.frontmatter.date}
                </small>
              )}
              {node.frontmatter.tags && (
                <small style={{ display: 'block' }}>
                  <TagList tags={node.frontmatter.tags}></TagList>
                </small>
              )}
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}

      {(!(isFirst && isLast)) && (
        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
              fontFamily: 'Dancing Script',
              ...scale(1 / 5),
              marginLeft: 0,
              paddingTop: rhythm(1)
            }}
          >
            <li>
              {!isFirst && (
                <Link to={prevPage} rel="prev">
                  ← Indietro
                </Link>
              )}
            </li>
            <li>
              {!isLast && (
                <Link to={nextPage} rel="next">
                  Avanti →
                </Link>
              )}
            </li>
          </ul>
        </nav>)}
    </Layout>
  )
}

export default BlogListTemplate

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "D MMMM YYYY", locale: "it")
            title
            description
            tags
          }
        }
      }
    }
  }
`;